/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Modal from '../../../global/components/widget/modal';
import {
  FORCE_UPDATE_DIALOG_CONTENT,
  DIALOG_CANCEL,
  UPDATE_IN_CLIENT,
  UPDATE_NATIVE_ZOOM_DIALOG_TITLE,
} from '../resource';
import { directReport } from '../../../global/logger/log-service/direct-report';

const defaultOkButtonProps = {
  type: 'primary',
  size: 'default',
  className: 'zm-btn-legacy',
};
const ForceUpdateMeetingEndedDialogComponent = (props) => {
  const { visible, setConfEndedDialogVisible, exitConf, isWFHPage } = props;
  let content = FORCE_UPDATE_DIALOG_CONTENT;

  const tagPrefix = isWFHPage ? 'isWFHPage_' : '';

  const onUpdate = () => {
    window.open('zoomus://zoom.us/router?page=home&mcv=1', '_blank');
    directReport(tagPrefix + 'update', {
      tags: ['isForceUpdateMeeting_action'],
    });
    setTimeout(onClose, 1000);
  };

  const onExit = () => {
    directReport(tagPrefix + 'cancel', {
      tags: ['isForceUpdateMeeting_action'],
    });
    onClose();
  };

  const onClose = () => {
    setConfEndedDialogVisible(false);
    exitConf({
      isLeave: true,
      isLeaveMeetingRightNow: true,
    });
  };

  useEffect(() => {
    if (visible) {
      directReport(tagPrefix + 'popup', {
        tags: ['isForceUpdateMeeting_action'],
      });
    }
  }, [visible, tagPrefix]);

  return (
    <Modal
      className="zm-modal-legacy"
      contentLabel="Meeting is end now"
      visible={visible}
      title={UPDATE_NATIVE_ZOOM_DIALOG_TITLE}
      okText={UPDATE_IN_CLIENT}
      okButtonProps={defaultOkButtonProps}
      cancelText={DIALOG_CANCEL}
      showClose={false}
      onCancel={onExit}
      onOk={onUpdate}
      onClose={onExit}
    >
      {content && <div className="content">{content}</div>}
    </Modal>
  );
};
export default ForceUpdateMeetingEndedDialogComponent;
